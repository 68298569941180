import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RwaworldApi from "../api";
import "../Stylesheets/ResearchPage.css";
import Newsletter from "../Newsletter/Newsletter";

const BlogArticles = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem("blogData");
        const cachedTimestamp = localStorage.getItem("blogDataTimestamp");
        const currentTime = new Date().getTime();

        if (
          cachedData &&
          cachedTimestamp &&
          currentTime - parseInt(cachedTimestamp) < 7 * 24 * 60 * 60 * 1000
        ) {
          // If cached data exists and is less than 7 days old, use it
          setBlogData(JSON.parse(cachedData));
        } else {
          // If no cached data exists or it's older than 7 days, fetch articles from the API
          const articles = await RwaworldApi.getBlog();
          // Cache the fetched articles in local storage along with the current timestamp
          console.log("These are the blog articles", articles)

          localStorage.setItem("blogData", JSON.stringify(articles));
          localStorage.setItem("articleDataTimestamp", currentTime.toString());
          setBlogData(articles);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchData();
  }, []);

  console.log(blogData)

  const getArticleTitle = (article) => {
    if (article.nodes && article.nodes.length > 0 && article.nodes[2].text) {
      return article.nodes[2].text.text;
    } else {
      return "Untitled Article";
    }
  };

  return (      
    <div id="researchpagediv">      
      {/* <h1 id="researchpagetitle">Blog</h1> */}
      <div id="researchMenu">
        {blogData.map((article) => (
          <div id="articlecardtitles" key={article.pageId}>
            <Link to={`/research/${article.pageId}`}>
              <h2 id="articletitle">{getArticleTitle(article)}</h2>
              <hr></hr>
            </Link>
          </div>
        ))}
      </div>
      {/* <Newsletter/> */}
    </div>
  );
};

export default BlogArticles;

